import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { languageNames } from '../../i18n';

/** PropTypes */
import { pagePropTypes } from '../../propTypes';

/** Redux */
import { clearFilters } from '../../redux/eventsSlice';

/** Hooks */
import { useMenu } from '../../hooks/useMenu';
import { useNavigateWithLoader } from '../../hooks/useNavigateWithLoader';

/** Components */
import Button from '../Button';
import Menu from '../Menu';
import Link from '../Link';
import Icon from '../Icon';

/**
 * <LanguageMenu />
 */

const LanguageMenu = ({ currentPage, ...props }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { navigate } = useNavigateWithLoader();

  /** Menu refs, state and props */
  const { menuTriggerRef, menuTriggerProps, menuRef, menuState, menuProps } = useMenu(props);

  /**
   * Navigate to the matching translated page and change the App language
   *
   * @param {string} language  The language to use
   */
  const changeLanguage = useCallback(
    (language) => {
      dispatch(clearFilters());
      navigate(currentPage.translations[language], currentPage.data.color || 'nature', () =>
        i18n.changeLanguage(language)
      );
      menuRef.current.close();
    },
    [currentPage, dispatch, i18n, menuRef, navigate]
  );

  return (
    <>
      <Button ref={menuTriggerRef} {...menuTriggerProps} className="menu-button">
        {i18n.language}
        <Icon icon="chevron-down" />
      </Button>
      {menuState.isOpen && (
        <Menu ref={menuRef} {...menuProps} position="right" shouldCloseOnBlur>
          <ul>
            {i18n.languages
              .filter((language) => Object.keys(currentPage.translations).includes(language))
              .map((language, index) => (
                <li key={index}>
                  <Link
                    className={language === i18n.language ? 'is-active' : ''}
                    url={currentPage.translations[language]}
                    title={languageNames[language]}
                    lang={language}
                    hrefLang={language}
                    onPress={() => changeLanguage(language)}
                  />
                </li>
              ))}
          </ul>
        </Menu>
      )}
    </>
  );
};

LanguageMenu.propTypes = {
  currentPage: PropTypes.shape(pagePropTypes).isRequired,
};

export default LanguageMenu;

/**
 * <BeeGreen />
 */

const BeeGreen = () => {
  return (
    <svg className="beegreen" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 400 98.34">
      <path
        id="b1b"
        className="letter"
        fill="#767362"
        d="M6.11,27.74c2.83-3.27,8.07-6.54,12.1-6.54c5.23,0,16.68,7.52,16.68,14.61v26.93
          c0,6.87-11.45,14.72-17.44,14.72C11.23,77.45,0,69.6,0,62.84V1.57C0,0.7,0.65,0.05,1.53,0.05h3.05c0.87,0,1.53,0.66,1.53,1.53
          V27.74z M17.55,26.97c-3.71,0-11.45,5.12-11.45,9.48v25.62c0,4.03,6.98,9.59,11.34,9.59c4.03,0,11.34-5.23,11.34-9.59V36.57
          C28.78,32.1,21.37,26.97,17.55,26.97z"
      />
      <path
        id="b2e"
        className="letter"
        fill="#767362"
        d="M75.77,48.23c0,1.09-1.64,2.94-2.94,2.94H47.1v10.68c0,4.58,7.41,9.81,11.34,9.81
          c3.16,0,8.72-2.94,11.88-5.02c0.98-0.55,2.07-0.44,2.51,0.55l1.09,1.85c0.33,0.76,0.22,1.64-0.65,2.18
          c-3.92,2.62-10.36,6.21-14.83,6.21c-6.11,0-17.44-7.63-17.44-14.83v-26.6c0-6.98,11.56-14.83,17.44-14.83
          c5.89,0,17.33,7.74,17.33,14.72V48.23z M47.1,36.68v9.38h22.68v-9.49c0-4.36-7.41-9.59-11.34-9.59
          C54.51,26.97,47.1,32.32,47.1,36.68z"
      />
      <path
        id="b3e"
        className="letter"
        fill="#767362"
        d="M116.65,48.23c0,1.09-1.64,2.94-2.94,2.94H87.98v10.68c0,4.58,7.41,9.81,11.34,9.81
          c3.16,0,8.72-2.94,11.88-5.02c0.98-0.55,2.07-0.44,2.51,0.55l1.09,1.85c0.33,0.76,0.22,1.64-0.65,2.18
          c-3.92,2.62-10.36,6.21-14.83,6.21c-6.11,0-17.44-7.63-17.44-14.83v-26.6c0-6.98,11.56-14.83,17.44-14.83
          c5.89,0,17.33,7.74,17.33,14.72V48.23z M87.98,36.68v9.38h22.68v-9.49c0-4.36-7.41-9.59-11.34-9.59
          C95.39,26.97,87.98,32.32,87.98,36.68z"
      />
      <g id="bee" className="bee">
        <polygon
          id="bee-bg"
          className="fill"
          fill="#72BE1E"
          points="132.53,29.45 132.53,70.24 167.85,90.63 203.18,70.24 203.18,29.45 167.85,9.05"
        />
        <path
          id="bee-eye-l"
          className="eye"
          fill="#F2FF00"
          d="M192.16,36.51l-0.85-1.48c-0.24-0.41-0.63-0.71-1.09-0.84c-0.46-0.12-0.95-0.06-1.36,0.18l-10.31,5.95
            c-0.03,0.02-0.05,0.04-0.07,0.05c-0.09,0.06-0.18,0.13-0.26,0.2c-0.04,0.04-0.07,0.07-0.1,0.11c-0.06,0.07-0.12,0.14-0.17,0.22
            c-0.03,0.04-0.05,0.08-0.08,0.12c-0.06,0.11-0.11,0.22-0.14,0.34c0,0.01-0.01,0.03-0.02,0.04c-0.04,0.14-0.05,0.28-0.06,0.42
            c0,0.02-0.01,0.03-0.01,0.05v4.08c0,0.64,0.34,1.24,0.9,1.56l3.53,2.04c0.28,0.16,0.59,0.24,0.9,0.24c0.31,0,0.62-0.08,0.9-0.24
            l3.53-2.04c0.56-0.32,0.9-0.91,0.9-1.56v-4.08c0-0.33-0.09-0.64-0.26-0.92l3.46-2C192.36,38.47,192.65,37.37,192.16,36.51z"
        />
        <path
          id="bee-eye-r"
          className="eye"
          fill="#F2FF00"
          d="M157.06,41.83c0-0.14-0.02-0.28-0.06-0.42c0-0.01-0.01-0.03-0.02-0.04c-0.03-0.12-0.08-0.23-0.14-0.34
            c-0.02-0.04-0.05-0.08-0.08-0.12c-0.05-0.08-0.11-0.15-0.17-0.22c-0.03-0.04-0.07-0.08-0.1-0.11c-0.08-0.07-0.17-0.14-0.26-0.2
            c-0.03-0.02-0.04-0.04-0.07-0.05l-10.31-5.95c-0.41-0.24-0.9-0.3-1.36-0.18c-0.46,0.12-0.85,0.42-1.09,0.84l-0.85,1.48
            c-0.5,0.86-0.2,1.96,0.66,2.45l3.46,2c-0.16,0.27-0.26,0.59-0.26,0.92v4.08c0,0.64,0.34,1.24,0.9,1.56l3.53,2.04
            c0.28,0.16,0.59,0.24,0.9,0.24c0.31,0,0.62-0.08,0.9-0.24l3.53-2.04c0.56-0.32,0.9-0.91,0.9-1.56v-4.08
            C157.07,41.86,157.06,41.85,157.06,41.83z"
        />
        <path
          id="bee-outline"
          className="outline"
          fill="#767362"
          d="M218.09,13.04c-3.92-2.62-10.36-6.21-14.83-6.21c-4.73,0-12.58,4.57-15.89,9.98L169.38,6.41
            c-0.95-0.55-2.11-0.55-3.05,0l-17.97,10.37c-3.32-5.4-11.16-9.96-15.88-9.96c-4.47,0-10.9,3.6-14.83,6.21
            c-0.87,0.55-0.98,1.42-0.65,2.18l1.09,1.85c0.44,0.98,1.53,1.09,2.51,0.55c3.16-2.07,8.72-5.02,11.88-5.02
            c3.19,0,8.69,3.46,10.63,7.22L131,26.8c-0.94,0.55-1.53,1.55-1.53,2.64v40.79c0,1.09,0.58,2.1,1.53,2.64l35.33,20.4
            c0.47,0.27,1,0.41,1.53,0.41c0.53,0,1.05-0.14,1.53-0.41l35.33-20.4c0.94-0.55,1.53-1.55,1.53-2.64V29.45
            c0-1.09-0.58-2.1-1.53-2.64l-12.08-6.97c1.94-3.76,7.44-7.23,10.64-7.23c3.16,0,8.72,2.94,11.88,5.02
            c0.98,0.55,2.07,0.44,2.51-0.55l1.09-1.85C219.08,14.45,218.97,13.58,218.09,13.04z M200.13,68.48l-32.27,18.63l-32.27-18.63
            V31.21l32.27-18.63l32.27,18.63V68.48z"
        />
      </g>
      <path
        id="g1g"
        className="letter"
        fill="#767362"
        d="M253.16,83.88c0,6.87-11.56,14.5-17.55,14.5c-4.25,0-9.81-2.83-14.17-5.34c-0.98-0.55-1.42-1.53-0.98-2.51
          l0.98-1.96c0.55-0.87,1.42-1.09,2.51-0.55c3.6,1.96,8.72,4.69,11.67,4.69c3.6,0,11.45-5.12,11.45-9.7v-12.1
          c-3.71,3.71-7.85,6.54-11.56,6.54c-6.21,0-17.23-7.85-17.23-14.61V35.91c0-7.2,11.77-14.72,17.44-14.72
          c5.67,0,17.44,7.52,17.44,14.72V83.88z M224.38,62.08c0,4.36,7.41,9.59,11.34,9.59c4.03,0,11.34-5.34,11.34-9.59v-25.4
          c0-4.47-7.52-9.7-11.34-9.7s-11.34,5.12-11.34,9.7V62.08z"
      />
      <path
        id="g2r"
        className="letter"
        fill="#767362"
        d="M281.4,26.73c-0.22,0.89-0.98,1.22-1.96,1c-1.09-0.22-2.83-0.55-3.93-0.55c-3.16,0-10.14,5.54-10.14,9.63
          V75.9c0,0.89-0.65,1.55-1.53,1.55h-3.05c-0.98,0-1.53-0.66-1.53-1.55V35.92c0-6.53,10.79-14.62,16.13-14.62
          c1.53,0,3.93,0.55,5.34,0.89c0.98,0.33,1.31,1.22,1.09,2.1L281.4,26.73z"
      />
      <path
        id="g3e"
        className="letter"
        fill="#767362"
        d="M318.35,48.23c0,1.09-1.64,2.94-2.94,2.94h-25.73v10.68c0,4.58,7.41,9.81,11.34,9.81
          c3.16,0,8.72-2.94,11.88-5.02c0.98-0.55,2.07-0.44,2.51,0.55l1.09,1.85c0.33,0.76,0.22,1.64-0.65,2.18
          c-3.92,2.62-10.36,6.21-14.83,6.21c-6.11,0-17.44-7.63-17.44-14.83v-26.6c0-6.98,11.56-14.83,17.44-14.83
          c5.89,0,17.33,7.74,17.33,14.72V48.23z M289.67,36.68v9.38h22.68v-9.49c0-4.36-7.41-9.59-11.34-9.59
          C297.09,26.97,289.67,32.32,289.67,36.68z"
      />
      <path
        id="g4e"
        className="letter"
        fill="#767362"
        d="M359.23,48.23c0,1.09-1.64,2.94-2.94,2.94h-25.73v10.68c0,4.58,7.41,9.81,11.34,9.81
          c3.16,0,8.72-2.94,11.88-5.02c0.98-0.55,2.07-0.44,2.51,0.55l1.09,1.85c0.33,0.76,0.22,1.64-0.65,2.18
          c-3.92,2.62-10.36,6.21-14.83,6.21c-6.11,0-17.44-7.63-17.44-14.83v-26.6c0-6.98,11.56-14.83,17.44-14.83s17.33,7.74,17.33,14.72
          V48.23z M330.55,36.68v9.38h22.68v-9.49c0-4.36-7.41-9.59-11.34-9.59C337.97,26.97,330.55,32.32,330.55,36.68z"
      />
      <path
        id="g5n"
        className="letter"
        fill="#767362"
        d="M393.89,37.03c0-4.87-7.52-9.97-11.23-9.97c-3.6,0-11.23,5.09-11.23,9.97v38.86c0,0.89-0.65,1.55-1.53,1.55
          h-3.05c-0.87,0-1.53-0.66-1.53-1.55V36.14c0-7.09,11.56-14.95,17.33-14.95S400,29.06,400,36.25v39.64c0,0.89-0.55,1.55-1.53,1.55
          h-3.05c-0.87,0-1.53-0.66-1.53-1.55V37.03z"
      />
    </svg>
  );
};

export default BeeGreen;

/**
 * <Cookie />
 */

const Cookie = () => {
  return (
    <svg className="cookie" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32">
      <path
        d="M16,32c-4.27,0-8.29-1.66-11.31-4.69C1.66,24.29,0,20.27,0,16c0-4.27,1.66-8.29,4.69-11.31C7.71,1.66,11.73,0,16,0
          c0.59,0,1.2,0.03,1.79,0.1c0.15,0.02,0.29,0.1,0.37,0.23c0.09,0.13,0.11,0.28,0.07,0.43c-0.08,0.3-0.12,0.6-0.12,0.91
          c0,1.67,1.19,3.09,2.84,3.36c0.18,0.03,0.33,0.15,0.39,0.32c0.07,0.17,0.05,0.36-0.06,0.5c-0.42,0.58-0.65,1.28-0.65,2
          c0,1.88,1.53,3.41,3.41,3.41c0.69,0,1.35-0.2,1.92-0.59c0.16-0.11,0.37-0.12,0.54-0.03c0.17,0.09,0.28,0.27,0.27,0.47l0,0.04
          c0,0.01,0,0.02,0,0.03c0,1.88,1.53,3.41,3.41,3.41c0.37,0,0.73-0.06,1.08-0.18c0.15-0.05,0.32-0.03,0.45,0.06
          c0.13,0.09,0.22,0.24,0.23,0.4C31.99,15.25,32,15.63,32,16c0,4.27-1.66,8.29-4.69,11.31C24.29,30.34,20.27,32,16,32z M16,1.04
          c-4,0-7.75,1.56-10.58,4.38C2.6,8.25,1.04,12,1.04,16s1.56,7.75,4.38,10.58C8.25,29.4,12,30.96,16,30.96c4,0,7.75-1.56,10.58-4.38
          C29.4,23.75,30.96,20,30.96,16c0-0.14,0-0.29-0.01-0.44c-0.25,0.04-0.5,0.06-0.76,0.06c-2.2,0-4.03-1.6-4.39-3.69
          c-0.55,0.24-1.15,0.36-1.76,0.36c-2.45,0-4.45-2-4.45-4.45c0-0.69,0.16-1.36,0.46-1.97c-1.77-0.61-2.99-2.28-2.99-4.21
          c0-0.2,0.01-0.39,0.04-0.59C16.74,1.05,16.37,1.04,16,1.04z"
      />
      <g>
        <path
          className="cookie-dot-1"
          d="M24.29,4.73c-1.18,0-2.13-0.96-2.13-2.13s0.96-2.13,2.13-2.13c1.18,0,2.13,0.96,2.13,2.13S25.47,4.73,24.29,4.73z
		        M24.29,1.53c-0.59,0-1.07,0.48-1.07,1.07s0.48,1.07,1.07,1.07c0.59,0,1.07-0.48,1.07-1.07S24.88,1.53,24.29,1.53z"
        />
        <path
          className="cookie-dot-2"
          d="M30.02,8.51c-0.97,0-1.76-0.79-1.76-1.76c0-0.97,0.79-1.76,1.76-1.76c0.97,0,1.76,0.79,1.76,1.76
            C31.78,7.71,30.99,8.51,30.02,8.51z M30.02,6.02c-0.4,0-0.72,0.33-0.72,0.72c0,0.4,0.33,0.72,0.72,0.72c0.4,0,0.72-0.33,0.72-0.72
            C30.74,6.34,30.41,6.02,30.02,6.02z"
        />
        <path
          className="cookie-dot-3"
          d="M25.38,9.23c-0.97,0-1.76-0.79-1.76-1.76c0-0.97,0.79-1.76,1.76-1.76c0.97,0,1.76,0.79,1.76,1.76
		        C27.15,8.44,26.36,9.23,25.38,9.23z"
        />
        <path
          d="M7.61,22.19c-0.97,0-1.76-0.79-1.76-1.76c0-0.97,0.79-1.76,1.76-1.76c0.97,0,1.76,0.79,1.76,1.76
            C9.37,21.4,8.58,22.19,7.61,22.19z M7.61,19.71c-0.4,0-0.72,0.33-0.72,0.72c0,0.4,0.33,0.72,0.72,0.72c0.4,0,0.72-0.33,0.72-0.72
            C8.33,20.03,8.01,19.71,7.61,19.71z"
        />
        <path
          d="M16.83,29.61c-0.97,0-1.76-0.79-1.76-1.76c0-0.97,0.79-1.76,1.76-1.76c0.97,0,1.76,0.79,1.76,1.76
            C18.59,28.82,17.8,29.61,16.83,29.61z M16.83,27.12c-0.4,0-0.72,0.33-0.72,0.72s0.33,0.72,0.72,0.72c0.4,0,0.72-0.33,0.72-0.72
            S17.23,27.12,16.83,27.12z"
        />
        <path
          d="M24.99,22.92c-0.97,0-1.76-0.79-1.76-1.76c0-0.97,0.79-1.76,1.76-1.76c0.97,0,1.76,0.79,1.76,1.76
            C26.75,22.13,25.96,22.92,24.99,22.92z M24.99,20.43c-0.4,0-0.72,0.33-0.72,0.72c0,0.4,0.33,0.72,0.72,0.72
            c0.4,0,0.72-0.33,0.72-0.72C25.71,20.76,25.39,20.43,24.99,20.43z"
        />
        <path
          d="M6.15,15.02c-0.97,0-1.76-0.79-1.76-1.76c0-0.97,0.79-1.76,1.76-1.76s1.76,0.79,1.76,1.76
            C7.91,14.23,7.12,15.02,6.15,15.02z M6.15,12.53c-0.4,0-0.72,0.33-0.72,0.72c0,0.4,0.33,0.72,0.72,0.72c0.4,0,0.72-0.33,0.72-0.72
            C6.87,12.86,6.55,12.53,6.15,12.53z"
        />
        <path
          d="M20.88,17.67c-0.97,0-1.76-0.79-1.76-1.76c0-0.97,0.79-1.76,1.76-1.76s1.76,0.79,1.76,1.76
            C22.64,16.88,21.85,17.67,20.88,17.67z M20.88,15.18c-0.4,0-0.72,0.33-0.72,0.72c0,0.4,0.33,0.72,0.72,0.72
            c0.4,0,0.72-0.33,0.72-0.72C21.6,15.5,21.28,15.18,20.88,15.18z"
        />
      </g>
      <g>
        <g>
          <path d="M18.8,24.53h-8.53V20.8h2.13v-4.27h-2.13V12.8h6.4v8h2.13V24.53z" />
          <path d="M14,11.73c-1.18,0-2.13-0.96-2.13-2.13S12.82,7.47,14,7.47c1.18,0,2.13,0.96,2.13,2.13S15.18,11.73,14,11.73z" />
        </g>
      </g>
    </svg>
  );
};

export default Cookie;

import { isFloat } from './Math';

/**
 * @typedef {string} DOMString  A DOM string
 */

/**
 * Normalize a string to unicode
 *
 * @param {string} string  The string to normalize
 * @returns {string}  The normalized string
 */
export const normalizeString = (string) => string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

/**
 * Format the first letter of a string to uppercase
 *
 * @param {string} string  The string to format
 * @returns {string}  The formatted string
 */
export const ucFirst = (string) => `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

/**
 * Format the first letter of a string to uppercase, and the rest to lowercase
 *
 * @param {string} param0  The string to format
 * @returns {string}  The formatted string
 */
export const ucFirstRestLower = ([first, ...rest]) => first.toUpperCase() + rest.join('').toLowerCase();

/**
 * Replace line breaks by HTML br tags
 *
 * @param {string} string The string to format
 * @returns {string}  The formatted string
 */
export const nl2br = (string) => string.replaceAll(/(?:\r\n|\r|\n)/g, '<br />');

/**
 * Replace the "œ" character in a text by a tag and the "oe" letters with negative letter-spacing,
 * replace the "♡" character in a text by an icon, as the font "Terfens" does not contain these characters.
 *
 * @param {string} text  The text to format
 * @returns {string}  The formatted text
 */
export const specialChars = (text) =>
  (text
    ? String(text)
        .replaceAll('œ', '<span class="oe">oe</span>')
        .replaceAll('♡', '<span class="icon icon-heart" aria-label="♡"></span>')
    : ''); // prettier-ignore

/**
 * Format a text for the display, adding unsecable spaces around specials characters (?,!,:,;,«,»)
 *
 * @param {string} text  The text to format
 * @param {boolean} formatSpecialChars  Whether to replace the special characters
 * @returns {string}  The formatted text
 */
export const formatText = (text, formatSpecialChars = false) => {
  formatSpecialChars && (text = specialChars(text));
  // prettier-ignore
  return text
    ? text
      .replaceAll('\'', '’')
      .replaceAll(' ?', '&nbsp;?')
      .replaceAll(' !', '&nbsp;!')
      .replaceAll(' :', '&nbsp;:')
      .replaceAll(' ;', '&nbsp;;')
      .replaceAll('« ', '«&nbsp;')
      .replaceAll(' »', '&nbsp;»')
    : '';
};

/**
 * Format a html text for the display, replacing HTML blockquotes tags by div.block tags, and removing orphan p tags
 *
 * @param {string} text  The text to format
 * @returns {string}  The formatted text
 */
export const formatHtmlText = (text) => {
  if (text) {
    text = specialChars(formatText(text));
    return text
      .replaceAll('<blockquote>', '<div class="block">')
      .replaceAll('</blockquote>', '</div>')
      .replaceAll('<iframe', '<span class="iframe"><iframe')
      .replaceAll('</iframe>', '</iframe></span>')
      .replaceAll('<p>&nbsp;</p>', '');
  } else {
    return '';
  }
};

/**
 * Replace text between stars (*) in a string by <strong> tags
 *
 * @param {string} str  The string to format
 * @returns {string}  The formatted string
 */
export const bbStars = (str) => str.replace(/\*([^*]+)\*/g, '<strong>$1</strong>');

/**
 * Format a title for the display
 *
 * @param {string} title  The title to format
 * @returns {string}  The formatted title
 */
export const formatHtmlTitle = (title) => {
  if (title) {
    title = formatText(title);
    return bbStars(title);
  } else {
    return '';
  }
};

/**
 * Format a phone number to a E.164 standardized phone number
 *
 * @param {string} tel  The phone number to format
 * @returns {string}  The formatted phone number
 */
export const formatTel = (tel) => tel.replaceAll(/[\s+()–-]/gm, '').replace(/^/, '+');

/**
 * Format an url for the display, removing the protocol & the eventual www
 *
 * @param {string} url  The url to format
 * @returns {string}  The formatted url
 */
export const formatUrl = (url) => url.replace(/https?:\/\/w{0,3}\.?/, '').replace(/\/$/, '');

/**
 * Format an address object into a DOMString
 *
 * @param {object} contact  The contact object
 * @param {string} contact.address  The street address
 * @param {string} contact.zip  The postal code
 * @param {string} contact.city  The city
 * @param {string} contact.country  The country
 * @returns {DOMString}  The formatted address
 */
export const formatAddress = (contact) => {
  let addressDOMString = '';
  contact.address && (addressDOMString += `<span class="p-street-address">${contact.address}</span>`);
  addressDOMString.length > 0 && (addressDOMString += '<br />');
  contact.zip && (addressDOMString += `<span class="p-postal-code">${contact.zip}</span> `);
  contact.city && (addressDOMString += `<span class="p-locality">${contact.city}</span> `);
  addressDOMString.length > 0 && (addressDOMString += '<br />');
  contact.country && (addressDOMString += `<span class="p-country-name">${contact.country}</span>`);
  return addressDOMString;
};

/**
 * Strip the html tags from a string
 *
 * @param {string|DOMString} text  The text to format
 * @returns {string}  The formatted text
 */
export const stripTags = (text) => {
  const tmp = document.createElement('div');
  tmp.innerHTML = text;
  return tmp.textContent || tmp.innerText || '';
};

/**
 * Convert a string to a float
 *
 * @param {string} string  The string
 * @returns {number}  The float
 */
export const stringToFloat = (string) =>
  (string ? parseFloat(typeof string === 'string' ? string.replace(',', '.') : string) : 0); // prettier-ignore

/**
 * Return whether a string is a number
 *
 * @param {string} string  The string
 * @returns {boolean}  Whether the string is a number
 */
export const isNumeric = (string) => {
  const float = stringToFloat(string);
  if (isNaN(float)) {
    return false;
  } else if (isFloat(float)) {
    return float.toString().length === string.replace(/0$/, '').length;
  } else {
    return float.toString().length === string.length;
  }
};

/**
 * Return whether a string is a valid url
 *
 * @param {string} string  The string
 * @returns {boolean}  Whether the string is a valid url
 */
export const isValidHttpUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

/**
 * Return whether a string is a valid email
 *
 * @param {string} string  The string
 * @returns {boolean}  Whether the string is a valid email
 */
export const isValidEmail = (string) => string.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

import PropTypes from 'prop-types';

/*******
 * APP *
 *******/

/**
 * The colors of the app
 */
const colors = ['charcoal', 'nature', 'honey', 'carrot', 'raspberry', 'iris', 'cornflower', 'aqua', 'wood', 'peanut'];
export const colorPropTypes = PropTypes.oneOf(['white', ...colors]);
export const colorPropTypesNoWhite = PropTypes.oneOf(colors);

/**
 * The image formats of the app -- Must match the custom image sizes settings in /app/setup.php
 */
export const imagePropTypes = {
  /** Image alt */
  alt: PropTypes.string,
  /** Landscape */
  landscape: PropTypes.shape({
    w3840h2560: PropTypes.string,
    w2880h1920: PropTypes.string,
    w1920h1280: PropTypes.string,
    w1440h960: PropTypes.string,
    w960h640: PropTypes.string,
  }),
  /** Portrait */
  portrait: PropTypes.shape({
    w2560h3840: PropTypes.string,
    w1920h2880: PropTypes.string,
    w1280h1920: PropTypes.string,
    w960h1440: PropTypes.string,
  }),
  /** Square */
  square: PropTypes.shape({
    w2560h2560: PropTypes.string,
    w1920h1920: PropTypes.string,
    w1440h1440: PropTypes.string,
    w960h960: PropTypes.string,
  }),
  /** Resized */
  resized: PropTypes.shape({
    w3840: PropTypes.string,
    w2880: PropTypes.string,
    w1920: PropTypes.string,
    w1440: PropTypes.string,
    w960: PropTypes.string,
  }),
  /** Logo */
  logo: PropTypes.shape({
    w1440: PropTypes.string,
    w960: PropTypes.string,
  }),
  /** Full */
  full: PropTypes.string,
  /** Thumbnail */
  thumbnail: PropTypes.string,
};

/**
 * The base props of a link
 */
export const linkPropTypes = {
  url: PropTypes.string.isRequired,
  target: PropTypes.string,
  title: PropTypes.string,
};

/**
 * The prop types of a date
 */
export const datePropTypes = {
  /** The start date */
  startDate: PropTypes.string,
  /** The start time */
  startTime: PropTypes.string,
  /** The end date */
  endDate: PropTypes.string,
  /** The end time */
  endTime: PropTypes.string,
};

/**
 * Props of a location lat-lng
 */
export const latLngPropTypes = {
  /** The latitude of a location */
  lat: PropTypes.number,
  /** The longitude of a location */
  lng: PropTypes.number,
};

/********
 * Rest *
 ********/

/**
 * Returned by all routes listing pages
 */
export const pagePropTypes = {
  /** The id of the page */
  id: PropTypes.number,
  /** The url of the page */
  url: PropTypes.string.isRequired,
  /** The rest url of the page */
  restUrl: PropTypes.string.isRequired,
  /** The title of the page */
  title: PropTypes.string.isRequired,
  /** The name of the template for the page */
  templateName: PropTypes.string.isRequired,
  /** A slug for the page, regardless the language */
  pageName: PropTypes.string.isRequired,
  /** The id of the parent of the page */
  parentId: PropTypes.number,
  /** The 2-letter language code of the page -- false is provided while the pages are loading */
  lang: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  /** An object of translations for the page {'language_code': 'url'} -- array is provided while the pages are loading */
  translations: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.string), PropTypes.array]).isRequired,
  /** The type of the page -- Added by <AppRouter /> to the <Template /> `page` prop  -- '' is provided while the pages are loading */
  type: PropTypes.oneOf(['page', 'festival', 'event', 'press', 'program', 'user', '404', '']),
  /** Additional data -- The type is array when there is no data */
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

/**
 * Returned by each single page route
 */
export const contentPropTypes = {
  /** The meta data of the page */
  meta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
};

/**********
 * EVENTS *
 **********/

/**
 * The image of an event
 */
export const eventImagePropTypes = PropTypes.shape({
  alt: imagePropTypes.alt,
  resized: imagePropTypes.resized,
  thumbnail: imagePropTypes.thumbnail,
});

/**
 * Informations about an event
 */
export const eventInfoPropTypes = PropTypes.shape({
  /** The headline of the event */
  headline: PropTypes.string,
  /** The description of the event */
  description: PropTypes.string.isRequired,
  /** The price of the event */
  price: PropTypes.string.isRequired,
  /** A link to the event website */
  link: PropTypes.string,
  /** The title of the link */
  linkTitle: PropTypes.string,
  /** The image of the event */
  image: eventImagePropTypes,
});

/**
 * The location of an event
 */
export const eventLocationPropTypes = PropTypes.shape({
  ...latLngPropTypes,
  /** The name of the place */
  name: PropTypes.string.isRequired,
  /** The address of the location */
  address: PropTypes.string.isRequired,
  /** The zip code of the location */
  zip: PropTypes.string.isRequired,
  /** The city of the location */
  city: PropTypes.string.isRequired,
  /** The country of the location as a 2-letter country code */
  country: PropTypes.string.isRequired,
});

/**
 * The dates of an event
 */
export const eventDatesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    /** The start date */
    startDate: PropTypes.string.isRequired,
    /** The end date */
    endDate: PropTypes.string.isRequired,
    /** The start time */
    startTime: PropTypes.string.isRequired,
    /** The end time */
    endTime: PropTypes.string.isRequired,
  })
);

/**
 * The promoter of an event
 */
export const eventPromoterPropTypes = PropTypes.shape({
  /** The name of the promoter */
  name: PropTypes.string.isRequired,
  /** The email of the promoter */
  email: PropTypes.string.isRequired,
  /** The phone number of the promoter */
  tel: PropTypes.string.isRequired,
  /** The website of the promoter */
  url: PropTypes.string,
  /** The logo of the promoter */
  logo: imagePropTypes.logo,
  /** The partners of the promoter */
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      /** The name of the partner */
      name: PropTypes.string.isRequired,
      /** The website of the partner */
      url: PropTypes.string,
    })
  ),
  reservation: PropTypes.shape({
    /** Reservation information / link title */
    title: PropTypes.string,
    /** Reservation website */
    url: PropTypes.string,
  }).isRequired,
});

/**
 * The category(-ies) of an event
 */
export const eventCategoryPropTypes = PropTypes.arrayOf(PropTypes.string);

/**
 * The thematic(s) of an event
 */
export const eventThematicPropTypes = PropTypes.arrayOf(PropTypes.string);

/*******
 * ACF *
 *******/

/**
 * The content of the ACF "Page header" group
 */
export const headerPropTypes = PropTypes.shape({
  /** The title of the page header */
  title: PropTypes.string,
  /** The headline of the page header */
  headline: PropTypes.string,
  /** A link to append to the page header */
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(linkPropTypes)]),
  /** The background color of the page header */
  color: colorPropTypesNoWhite,
});

/**
 * The content of an item of the ACF "Sponsors" group repeater
 */
export const sponsorItemPropTypes = PropTypes.shape({
  /** The name of the sponsor */
  name: PropTypes.string.isRequired,
  /** The description of the sponsor */
  description: PropTypes.string.isRequired,
  /** The photo of the sponsor */
  photo: PropTypes.shape({
    square: imagePropTypes.square,
    alt: imagePropTypes.alt,
  }).isRequired,
});

/**
 * The content of the ACF "Sponsors" group repeater
 */
export const sponsorsPropTypes = PropTypes.shape({
  /** The title of the element */
  title: PropTypes.string.isRequired,
  /** The color of the element */
  color: colorPropTypesNoWhite,
  /** The sponsors */
  items: PropTypes.arrayOf(sponsorItemPropTypes).isRequired,
});

/**
 * The content of an item of the ACF "Partners" group repeater
 */
export const partnersPropTypes = PropTypes.shape({
  /** The title of the element */
  title: PropTypes.string.isRequired,
  /** The color of the element */
  color: colorPropTypesNoWhite,
  /** The partners */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** The name of the partner */
      name: PropTypes.string.isRequired,
      /** The logo of the partner */
      logo: imagePropTypes.logo.isRequired,
      /** The url of the partner */
      url: PropTypes.string,
    })
  ).isRequired,
});

/**
 * The content of the ACF "Flexible content" group
 */
export const flexiblePropTypes = PropTypes.arrayOf(PropTypes.shape()); // TODO

/**
 * The content of the data object for the "event" pages
 */
export const eventDataPropTypes = PropTypes.shape({
  /** The main date of the event -- Added by `eventsSlice.saveList` */
  ...datePropTypes,
  /** The other dates of the event */
  dates: PropTypes.arrayOf(PropTypes.shape(datePropTypes)).isRequired,
  /** The id of the festival of the event */
  festival: PropTypes.number.isRequired,
  /** The city of the event */
  city: PropTypes.string.isRequired,
  /** The `thematic` taxonomy terms */
  thematic: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** The `category` taxonomy terms */
  category: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** The promoter of the event */
  promoter: PropTypes.string.isRequired,
});

const propTypes = {
  page: pagePropTypes,
  content: contentPropTypes,
};

export default propTypes;

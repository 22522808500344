import PropTypes from 'prop-types';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** Redux */
import { selectList as selectFestivalsList } from '../../redux/festivalsSlice';
import { selectPage } from '../../redux/pagesSlice';

/** Hooks */
import { useNavigateWithLoader } from '../../hooks/useNavigateWithLoader';

/** Utils */
import { getLogoByLanguage } from '../../util/Images';
import { formatText } from '../../util/String';

/** Components */
import Headline from '../Headline';
import Link from '../Link';
import FestivalsMap from '../FestivalsMap';
import logoFR from '../../images/logo-fr-landscape-white.svg';
import logoNL from '../../images/logo-nl-landscape-white.svg';

const logos = { fr: logoFR, nl: logoNL };

/**
 * <Hero />
 */

export const Hero = ({ layout, ...props }) => {
  const { t: __, i18n } = useTranslation();
  const { navigate } = useNavigateWithLoader();

  const headline = layout === 'festivals' ? 'The Feeding festivals' : 'Food transition festivals';

  /** Redux */
  const festivalslist = useSelector((state) => selectFestivalsList(state, i18n.language, true));
  const pageProgram = useSelector((state) => selectPage(state, 'program', i18n.language));
  const pageCreateFestival = useSelector((state) => selectPage(state, 'create-festival', i18n.language));
  const pageCreateEvent = useSelector((state) => selectPage(state, 'create-event', i18n.language));

  return (
    <div className={`hero l-${layout} bg-nature`}>
      <div>
        {layout === 'app' && (
          <span>
            <img className="hero-logo" src={getLogoByLanguage(logos, i18n.language)} alt={__('app.siteName')} />
          </span>
        )}
        <Headline className="hero-headline" i18nTitle={headline} />
        <div
          className="hero-content"
          style={{ '--festivals-count': festivalslist.length, '--row-count': Math.ceil(festivalslist.length / 3) }}
        >
          {layout === 'app' && <h3 className="hero-title">{__('template.front-page.Upcoming festivals')}</h3>}
          <ul
            className={`hero-festivals ${
              festivalslist.filter((festival) => festival.data.city.length > 18).length > 0 ? 'has-long-names' : ''
            }`}
          >
            {festivalslist.map((festival, index) => (
              <li key={index}>
                <Link
                  url={festival.url}
                  onPress={() => {
                    navigate(festival.url, festival.data.color, props.onClose);
                  }}
                >
                  <span dangerouslySetInnerHTML={{ __html: formatText(festival.data.city, true) }} />
                  &nbsp;<span className="year">{festival.data.year}</span>
                </Link>
              </li>
            ))}
          </ul>
          {layout === 'app' && (
            <Link
              layout="button-outline"
              color="white"
              url={pageProgram.url}
              title={__('button.Detailed program')}
              loaderColor={pageProgram.data.color}
            />
          )}
          {layout === 'festivals' && (
            <div className="hero-links">
              <Link
                url={pageCreateFestival.url}
                onPress={() => {
                  navigate(pageCreateFestival.url, pageCreateFestival.data.color, props.onClose);
                }}
              >
                <h3>{pageCreateFestival.title}</h3>
                {pageCreateFestival.data.menuLabel && <p>{pageCreateFestival.data.menuLabel}</p>}
              </Link>
              <Link
                url={pageCreateEvent.url}
                onPress={() => {
                  navigate(pageCreateEvent.url, pageCreateEvent.data.color, props.onClose);
                }}
              >
                <h3>{pageCreateEvent.title}</h3>
                {pageCreateEvent.data.menuLabel && <p>{pageCreateEvent.data.menuLabel}</p>}
              </Link>
            </div>
          )}
        </div>
      </div>
      <div>
        <FestivalsMap className="hero-map" festivals={festivalslist} onClose={props.onClose} />
      </div>
    </div>
  );
};

Hero.propTypes = {
  /** The layout to apply to the element */
  layout: PropTypes.oneOf(['app', 'festivals']).isRequired,
  /** A callback for the onClose event */
  onClose: PropTypes.func,
};
Hero.defaultProps = {
  onClose: () => {},
};

export default memo(Hero);

/**
 * Return whether the argument is an integer
 *
 * @param {any} n  The "number"
 * @returns {boolean}  Whether the argument is an integer
 */
export const isInt = (n) => {
  return Number(n) === n && n % 1 === 0;
};

/**
 * Return whether the argument is an float
 *
 * @param {any} n  The "number"
 * @returns {boolean}  Whether the argument is an float
 */
export const isFloat = (n) => {
  return Number(n) === n && n % 1 !== 0;
};

import { useCallback } from 'react';

/**
 * useCloseWithTransition Hook
 *
 * Add a is-closing CSS class to the element and remove it after a given delay to let CSS transitions apply.
 * Call the given callback after the given delay or immediatly if the user doesn't want animations.
 *
 * @param {object} elementRef  The React ref to the element
 * @param {Function} callback  The callback function
 * @param {number} delay  The .is-closing CSS transition duration in milliseconds
 * @returns {Function}  The hook callback function
 */
const useCloseWithTransition = (elementRef, callback, delay) => {
  /**
   * closeWithTransition memoized callback function
   */
  const closeWithTransition = useCallback(() => {
    if (elementRef.current) {
      if (window.matchMedia('(prefers-reduced-motion: no-preference)').matches) {
        elementRef.current.classList.add('is-closing');
        setTimeout(() => {
          /**
           * Additional delay is added to handle elements that are conditionally displayed via Redux,
           * and so avoid a flash while the state is updating.
           */
          setTimeout(() => {
            elementRef.current && elementRef.current.classList.remove('is-closing');
          }, 100);
          callback();
        }, delay);
      } else {
        callback();
      }
    }
  }, [elementRef, callback, delay]);

  /** Return the hook callback function */
  return closeWithTransition;
};

export { useCloseWithTransition };
export default useCloseWithTransition;

import { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

/** Redux */
import { isLogged, deleteUser } from '../redux/userSlice';

/** Utils */
import { fetchAndParseJSON } from '../util/Fetch';
import { deleteCookie } from '../util/Cookies';

/**
 * @typedef {object} Auth  The response from the auth api
 * @property {boolean} isAuth  Whether the token is valid
 * @property {string} message  The authentication message
 */

/**
 * useAuth Hook
 *
 * Validate the application token for a user.
 *
 * @param {Function} onAuth A callback for the fetch response
 * @returns {Auth} The response from the auth api
 */
const useAuth = (onAuth = () => {}) => {
  const dispatch = useDispatch();

  const isUserLogged = useSelector(isLogged);
  const user = useSelector((state) => state.user);
  const [values, setValues] = useState({ isAuth: false, message: '' });
  const isLoading = useRef(false);

  /** Verify auth from the app */
  useEffect(() => {
    if (!isLoading.current && isUserLogged) {
      isLoading.current = true;
      fetchAndParseJSON(`${process.env.REACT_APP_REST_URL}auth`, {
        'X-APP-USER': encodeURIComponent(window.btoa(`${user.email}:${user.token}`)),
      })
        .then(({ isAuth, message }) => {
          if (!isAuth) {
            deleteCookie(process.env.REACT_APP_USER_COOKIE);
            dispatch(deleteUser());
          }
          onAuth(isAuth);
          return setValues({ isAuth, message });
        })
        .catch(console.error);
    }
  }, [isLoading, isUserLogged, user, setValues, dispatch, onAuth]);

  /** Return the values */
  return values;
};

export { useAuth };
export default useAuth;

import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

/** Redux */
import { saveIsMenuOpen } from '../../redux/festivalsSlice';

/** Layouts */
import Hero from '../layouts/Hero';

/** Components */
import Modal from '../Modal';
import Button from '../Button';

/**
 * <FestivalsMenu />
 */

const FestivalsMenu = ({ id }) => {
  process.env.NODE_ENV === 'development' && console.info('<FestivalsMenu />');

  const dispatch = useDispatch();
  const { t: __ } = useTranslation();

  /** Overlay props */
  const buttonRef = useRef();
  const overlayState = useOverlayTriggerState({});
  const { triggerProps, overlayProps } = useOverlayTrigger({ type: 'dialog' }, overlayState, buttonRef);

  /** Handle the menu state via Redux, allowing to open it via links in the backend */
  const isMenuOpen = useSelector((state) => state.festivals.menu[id].isOpen);
  const setIsMenuOpen = useCallback((isOpen) => dispatch(saveIsMenuOpen({ id, isOpen })), [dispatch, id]);
  useEffect(() => {
    isMenuOpen && !overlayState.isOpen
      ? overlayState.open()
      : !isMenuOpen && overlayState.isOpen && overlayState.close();
  }, [isMenuOpen, overlayState]);

  return (
    <>
      <Button ref={buttonRef} {...triggerProps} className="modal-button" onPress={() => setIsMenuOpen(true)}>
        {__('menu.The festivals')}
      </Button>
      {overlayState.isOpen && (
        <Modal
          {...overlayProps}
          state={overlayState}
          className="template"
          color="nature"
          onClose={() => setIsMenuOpen(false)}
        >
          <Hero
            layout="festivals"
            onClose={() => {
              overlayState.close();
              setIsMenuOpen(false);
            }}
          />
        </Modal>
      )}
    </>
  );
};
FestivalsMenu.propTypes = {
  /** An unique identifier for the menu */
  id: PropTypes.string.isRequired,
};

export default FestivalsMenu;

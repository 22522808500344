import { useEffect } from 'react';

/**
 * useScrollbarWidth Hook
 *
 * Updates the `--scrollbar-width` css variables on different events:
 *
 * @event window#custom-resize || window#resize
 * @event window#template-load
 * @event window#images-load
 */
const useScrollbarWidth = () => {
  const getScrollbarWidth = () => window.innerWidth - document.documentElement.clientWidth;
  const setScrollbarWidth = () => {
    const html = document.querySelector('html');
    let restoreOverflow = false;

    /** Remove the overflow:hidden style from the html element before calculating the width */
    new Promise((resolve) => {
      if (html.style.overflow === 'hidden') {
        html.style.overflow = 'visible';
        restoreOverflow = true;
      }
      document.documentElement.style.setProperty('--scrollbar-width', `${getScrollbarWidth()}px`);
      return resolve(restoreOverflow);
    })
      .then((restoreOverflow) => {
        if (restoreOverflow) {
          html.style.overflow = 'hidden';
        }
        return;
      })
      .catch(() => {});
  };

  /** Bind/unbind the events handlers */
  useEffect(() => {
    setScrollbarWidth();
    window.addEventListener(window.customResize ? 'custom-resize' : 'resize', setScrollbarWidth);
    window.addEventListener('template-load', setScrollbarWidth);
    window.addEventListener('images-load', setScrollbarWidth);
    return () => {
      window.removeEventListener(window.customResize ? 'custom-resize' : 'resize', setScrollbarWidth);
      window.removeEventListener('template-load', setScrollbarWidth);
      window.removeEventListener('images-load', setScrollbarWidth);
      window.customResize = false;
    };
  });
};

export { useScrollbarWidth };
export default useScrollbarWidth;

import { useCallback } from 'react';

/**
 * useRecaptcha Hook
 *
 * Load the Google reCAPTCHA v3 script after detecting if it is not already present.
 *
 * @param {string} reCaptchaKey  The Google reCAPTCHA public key
 * @returns {Function}  The hook callback function
 */
const useRecaptcha = (reCaptchaKey) => {
  /**
   * loadRecaptcha callback function
   */
  const loadRecaptcha = useCallback(() => {
    if (document.getElementById('recaptcha-script') === null) {
      const script = document.createElement('script');
      script.id = 'recaptcha-script';
      script.src = `https://www.google.com/recaptcha/api.js?render=${reCaptchaKey}`;
      document.querySelector('body').appendChild(script);
    }
  }, [reCaptchaKey]);

  /** Return the hook callback function */
  return loadRecaptcha;
};

export { useRecaptcha };
export default useRecaptcha;

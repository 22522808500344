import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/** Hooks */
import { useMenu } from '../hooks/useMenu';

/** Components */
import Button from './Button';
import Menu from './Menu';
import Cookie from './images/Cookie';

const CookieNotice = ({ children, ...props }) => {
  const { t: __ } = useTranslation();

  /** Menu refs, state and props */
  const { menuTriggerRef, menuTriggerProps, menuRef, menuState, menuProps } = useMenu(props);

  return (
    <div className="cookie-notice">
      <Button ref={menuTriggerRef} {...menuTriggerProps} className="cookie-button">
        <span className="label">{__('cookie.Cookie information')}</span> <Cookie />
      </Button>
      {menuState.isOpen && (
        <Menu ref={menuRef} {...menuProps} position="top-left" shouldCloseOnBlur>
          {children}
        </Menu>
      )}
    </div>
  );
};

CookieNotice.propTypes = {
  /** The children of the element */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default CookieNotice;

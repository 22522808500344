import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

/** PropTypes */
import { pagePropTypes } from '../propTypes';

/** Hooks */
import { useOnResize } from '../hooks/useOnResize';
import { useNavigateWithLoader } from '../hooks/useNavigateWithLoader';

/** Utils */
import { breakpointUp } from '../util/Breakpoint';

/** Components */
import Headline from './Headline';
import Link from './Link';
import Icon from './Icon';
import mapSvg from '../images/belgium-vegetables.svg'; // 400Ko (gzipped)
import map1920 from '../images/belgium-vegetables-1920.png'; // Larger size png's are heavier than gzipped svg
import map1440 from '../images/belgium-vegetables-1440.png';
import map960 from '../images/belgium-vegetables-960.png';

/**
 * <FestivalsMap />
 */

const FestivalsMap = ({ layout, className, festivals, ...props }) => {
  const { t: __ } = useTranslation();
  const { navigate } = useNavigateWithLoader();

  /**
   * Get the responsive map image
   *
   * @returns {string}  A path to the map image
   */
  const getResponsiveMap = () => {
    return window.matchMedia(breakpointUp('xl')).matches
      ? mapSvg
      : window.matchMedia(breakpointUp('md')).matches
      ? map1920
      : window.matchMedia(breakpointUp('xs')).matches
      ? map1440
      : map960;
  };
  const [responsiveMap, setResponsiveMap] = useState(getResponsiveMap());
  useOnResize(() => setResponsiveMap(getResponsiveMap()));

  return (
    <div className={`festivals-map ${layout ? `l-${layout}` : ''} ${className}`}>
      <Headline i18nTitle="Choose your festival" tag="p" />
      <img className="festivals-map-image" src={responsiveMap} alt={__('app.Belgium map')} />
      <ul className="festivals-map-icons">
        {/* Sorting the list allows the infoboxes of the southern icons on the map to appear over the nortest ones */}
        {[...festivals]
          .sort((a, b) => (a.data.mapX > b.data.mapX ? 1 : 0))
          .sort((a, b) => (a.data.mapY > b.data.mapY ? 1 : 0))
          .map((festival, index) => (
            <li key={index} style={{ left: `${festival.data.mapX}%`, top: `${festival.data.mapY}%` }}>
              <Link url={festival.url} onPress={() => navigate(festival.url, festival.data.color, props.onClose)}>
                <Icon icon="logo" ariaLabel={festival.title} />
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};

FestivalsMap.propTypes = {
  /** The layout of the element */
  layout: PropTypes.oneOf(['belgium']),
  /** The class names to add to the element */
  className: PropTypes.string,
  /** The festivals */
  festivals: PropTypes.arrayOf(PropTypes.shape(pagePropTypes)).isRequired,
  /** A callback for the onClose event */
  onClose: PropTypes.func,
};
FestivalsMap.defaultProps = {
  layout: 'belgium',
  className: '',
  onClose: () => {},
};

export default FestivalsMap;

import PropTypes from 'prop-types';
import { forwardRef, memo } from 'react';
import { useButton, mergeProps } from 'react-aria';

/** PropTypes */
import { colorPropTypes } from '../propTypes';

/** Components */
import Icon from './Icon';

/**
 * <Button />
 */

const Button = forwardRef(({ layout, color, className, icon, iconLayout, children, ...props }, buttonRef) => {
  const { isDisabled } = props;

  /** Props */
  const { buttonProps } = useButton(props, buttonRef);
  const ariaProps = { 'aria-label': props.ariaLabel, 'aria-hidden': props.ariaHidden, role: props.role };
  const mouseProps = { onMouseEnter: props.onMouseEnter, onMouseLeave: props.onMouseLeave };

  /** Class names */
  const classNames = ['button'];
  layout && classNames.push(`l-${layout}`);
  color && classNames.push(`c-${color}`);
  className && classNames.push(className);
  isDisabled && classNames.push('is-disabled');

  return (
    <button ref={buttonRef} {...mergeProps(buttonProps, ariaProps, mouseProps)} className={classNames.join(' ')}>
      {children ? children : icon && <Icon icon={icon} layout={iconLayout} />}
    </button>
  );
});

Button.displayName = 'Button';

/**
 * `children` and `icon` props can't be set together.
 * Don't forget to provide an `ariaLabel` prop if you provide the `icon` prop.
 */
Button.propTypes = {
  /** The layout of the element */
  layout: PropTypes.oneOf(['outline', 'plain', 'arrow']),
  /** The color of the element */
  color: colorPropTypes,
  /** The class names to add to the element */
  className: PropTypes.string,
  /** Whether the element must be disabled */
  isDisabled: PropTypes.bool,
  /** The children of the element */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /** The icon to set as the element's child */
  icon: PropTypes.string, // @see Icon.propTypes
  /** The layout of the icon */
  iconLayout: PropTypes.string, // @see Icon.propTypes
  /** The ARIA label to set to the element when using an icon as its child */
  ariaLabel: PropTypes.string,
  /** Whether to hide the element for ARIA */
  ariaHidden: PropTypes.bool,
  /** An aria role */
  role: PropTypes.string,
  /** A callback for the onPress event */
  onPress: PropTypes.func,
  /** A callback for the onMouseEnter event */
  onMouseEnter: PropTypes.func,
  /** A callback for the onMouseLeave event */
  onMouseLeave: PropTypes.func,
};
Button.defaultProps = {
  disabled: false,
};

export default memo(Button);

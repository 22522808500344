export const breakpoints = {
  width: {
    null: 0,
    xxs: 460,
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1560,
    xxl: 1900,
    xxxl: 2260,
    huge: 2520,
  },
  height: {
    null: 0,
    xxs: 320,
    xs: 420,
    sm: 540,
    md: 660,
    lg: 800,
    xl: 940,
    xxl: 1080,
    xxxl: 1220,
    huge: 1360,
  },
};

/**
 * Return media query up for matched breakpoint
 *
 * @param {string} bp  Breakpoint key in `breakpoints` array
 * @param {string} query  (width|height) media query target
 * @returns {string}  The media query string
 */
export const breakpointUp = (bp, query = 'width') => {
  return `(min-${query}: ${breakpoints[query][bp]}px)`;
};

/**
 * Return media query down for matched breakpoint
 *
 * @param {string} bp  Breakpoint key in `breakpoints` array
 * @param {string} query  (width|height) media query target
 * @returns {string}  The media query string
 */
export const breakpointDown = (bp, query = 'width') => {
  return `(max-${query}: ${breakpoints[query][bp] - 1}px)`;
};

/**
 * Return media query between for matched breakpoint
 *
 * @param {string} bpMin  Breakpoint key in `breakpoints` array
 * @param {string} bpMax  Breakpoint key in `breakpoints` array
 * @param {string} query  (width|height) media query target
 * @returns {string}  The media query string
 */
export const breakpointBetween = (bpMin, bpMax, query = 'width') => {
  return `(min-${query}: ${breakpoints[query][bpMin]}px) and (max-${query}: ${breakpoints[query][bpMax] - 1}px)`;
};

export default breakpoints;

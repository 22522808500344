import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** Redux */
import { saveCurrentPage } from '../../redux/appSlice';
import { selectPage } from '../../redux/pagesSlice';

/** Hooks */
import { useMeta } from '../../hooks/useMeta';
import { useNavigateWithLoader } from '../../hooks/useNavigateWithLoader';

/** Layouts */
import { Template, TemplateHeader, TemplateTitle, TemplateContent } from '../layouts/Template';
import NoResult from '../layouts/NoResult';

/**
 * <Page404 />
 */

const Page404 = (props) => {
  const { t: __, i18n } = useTranslation();
  const dispatch = useDispatch();
  const setMeta = useMeta();
  const { removeLoader } = useNavigateWithLoader();

  const frontPage = useSelector((state) => selectPage(state, 'frontpage', i18n.language));
  const page = useMemo(
    () => ({
      url: '',
      restUrl: '',
      title: __('template.404.Page not found'),
      templateName: 'Page404',
      pageName: 'page-404',
      lang: i18n.language,
      translations: frontPage.translations,
      data: [],
      type: '404',
    }),
    [__, i18n.language, frontPage]
  );

  /** Set the meta data of the page */
  setMeta({ ...page, description: __('template.404.The page you are looking for does not exist') });

  /**
   * Save the current page to Redux
   */
  useEffect(() => {
    dispatch(saveCurrentPage(page));
  }, [dispatch, page]);

  /** Remove the loader */
  useEffect(() => {
    removeLoader();
  }, [removeLoader]);

  return (
    <Template page={page}>
      <TemplateHeader color="nature">
        <TemplateTitle>{__('template.404.Page not found')}</TemplateTitle>
      </TemplateHeader>
      <TemplateContent>
        <NoResult title={__('template.404.The page you are looking for does not exist')} />
      </TemplateContent>
    </Template>
  );
};

export default Page404;

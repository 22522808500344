import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** PropTypes */
import { pagePropTypes } from '../../propTypes';

/** Redux */
import { isLogged, selectPagesList as selectUserPages } from '../../redux/userSlice';
import { selectPage } from '../../redux/pagesSlice';

/** Hooks */
import { useMenu } from '../../hooks/useMenu';
import { useNavigateWithLoader } from '../../hooks/useNavigateWithLoader';
import { useAuth } from '../../hooks/useAuth';

/** Components */
import Button from '../Button';
import Menu from '../Menu';
import Link from '../Link';
import Icon from '../Icon';
import LoginForm from '../forms/LoginForm';

/**
 * <UserMenu />
 */

const UserMenu = ({ currentPage, ...props }) => {
  useAuth();
  const { navigate } = useNavigateWithLoader();
  const { i18n } = useTranslation();

  const frontPage = useSelector((state) => selectPage(state, 'frontpage', i18n.language));
  const pagesList = useSelector((state) => state.pages.list);
  const festivalsList = useSelector((state) => state.festivals.list);
  const eventsList = useSelector((state) => state.events.list);
  const pressList = useSelector((state) => state.press.list);
  const userPagesList = useSelector((state) => selectUserPages(state, i18n.language));

  /** Menu refs, state and props */
  const { menuTriggerRef, menuTriggerProps, menuRef, menuState, menuProps } = useMenu(props);

  /** User */
  const isUserLogged = useSelector(isLogged);
  const user = useSelector((state) => state.user);

  return (
    isUserLogged && (
      <>
        <Button ref={menuTriggerRef} {...menuTriggerProps} className="menu-button">
          <Icon icon="user" />
          <span className="button-label">
            <span>{user.name}</span>
          </span>
          <Icon icon="chevron-down" />
        </Button>
        {menuState.isOpen && (
          <Menu ref={menuRef} {...menuProps} position="bottom" shouldCloseOnBlur>
            <ul>
              {userPagesList.map((page, index) => (
                <li key={index}>
                  <Link
                    url={page.url}
                    title={page.title}
                    onPress={() => {
                      menuState.close();
                      navigate(page.url, page.data.color);
                    }}
                  />
                </li>
              ))}
            </ul>
            <LoginForm
              onLogout={(data) => {
                menuState.close();
                pagesList.filter((page) => page.url === currentPage.url).length === 0 &&
                  festivalsList.filter((page) => page.url === currentPage.url).length === 0 &&
                  eventsList.filter((page) => page.url === currentPage.url).length === 0 &&
                  pressList.filter((page) => page.url === currentPage.url).length === 0 &&
                  navigate(frontPage.url, frontPage.data.color);
              }}
            />
          </Menu>
        )}
      </>
    )
  );
};

UserMenu.propTypes = {
  currentPage: PropTypes.shape(pagePropTypes).isRequired,
};

export default UserMenu;

import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

/**
 * A Redux slice for the global app properties
 */

/**
 * @typedef {object} Page  A page
 * @property {number} id  The id of the page
 * @property {string} url  The url of the page
 * @property {string} restUrl  The rest url of the page
 * @property {string} title  The title of the page
 * @property {string} templateName  The name of the template for the page
 * @property {string} pageName  A slug for the page, regardless the language
 * @property {number} parentId  The id of the parent of the page
 * @property {string} lang  The 2-letter language code of the page
 * @property {object} translations  An object of translations for the page {'language_code': 'url'}
 * @property {'page'|'festival'|'event'|'press'|'program'|'404'|''} type  The type of a page -- Added by <AppRouter />
 * @property {Array|object} data  Additional data -- The type is array when there is no data
 */

/** @type {Page} */
const defaultPageState = {
  id: 0,
  url: '/',
  restUrl: '',
  title: '',
  templateName: '',
  pageName: '',
  parentId: 0,
  lang: '',
  translations: {},
  type: '',
  data: [],
};

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    isContentLoaded: false,
    loaderColor: 'nature',
    /** @type {Page} */
    currentPage: defaultPageState,
    /** @type {Page} */
    previousPage: defaultPageState,
  },
  reducers: {
    /**
     * Save the `isContentLoaded` state
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {boolean} action.payload  Whether the content is loaded
     */
    saveIsContentLoaded: (state, action) => {
      state.isContentLoaded = action.payload;
    },

    /**
     * Save the `loaderColor` state
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {string} action.payload  The loader color
     */
    saveLoaderColor: (state, action) => {
      state.loaderColor = action.payload;
    },

    /**
     * Save the `currentPage` state
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {object} action.payload  The current page
     */
    saveCurrentPage: (state, action) => {
      if (state.previousPage.id === 0 || state.previousPage.url !== state.currentPage.url) {
        state.previousPage = state.currentPage;
      }
      state.currentPage = action.payload;
    },
  },
});

export const { saveIsContentLoaded, saveLoaderColor, saveCurrentPage } = appSlice.actions;

/**
 * Return whether the user has a history on the app (did not land to the current page).
 */
export const selectHasHistory = createSelector(
  (state) => state.app,
  (app) =>
    app.previousPage.url !== app.currentPage.url &&
    app.previousPage.lang === app.currentPage.lang &&
    app.previousPage.type !== '' &&
    app.previousPage.type !== '404'
);

export default appSlice.reducer;

import { configureStore } from '@reduxjs/toolkit';

import appReducer from './appSlice';
import userReducer from './userSlice';
import optionsReducer from './optionsSlice';
import pagesReducer from './pagesSlice';
import festivalsReducer from './festivalsSlice';
import eventsReducer from './eventsSlice';
import pressReducer from './pressSlice';

export default configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    options: optionsReducer,
    pages: pagesReducer,
    festivals: festivalsReducer,
    events: eventsReducer,
    press: pressReducer,
  },
});

import { useState, useEffect } from 'react';

/**
 * useIsScrolled Hook
 *
 * Return whether the scroll position is at the top of the page.
 * The value is updated only if the scroll position leaves the top of the page or returns to it.
 * A delay is added to the scroll handler for better performance.
 *
 * @returns {boolean}  Whether the scroll position is at the top of the page
 */
const useIsScrolled = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  let isDelayed = false;

  /**
   * Scroll handler
   */
  const scrollHandler = () => {
    if (!isDelayed) {
      isDelayed = true;
      setTimeout(() => {
        isDelayed = false;
        if (window.scrollY > 0 && !isScrolled) {
          setIsScrolled(true);
        } else if (window.scrollY === 0 && isScrolled) {
          setIsScrolled(false);
        }
      }, 100);
    }
  };

  /** Bind/unbind the scroll handler */
  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  });

  /** Return whether the scroll position is at the top of the page */
  return isScrolled;
};

export { useIsScrolled };
export default useIsScrolled;

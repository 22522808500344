import PropTypes from 'prop-types';
import { useCallback, forwardRef, memo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLink, mergeProps } from 'react-aria';

/** PropTypes */
import { colorPropTypes, colorPropTypesNoWhite, linkPropTypes } from '../propTypes';

/** Redux */
import { saveIsMenuOpen } from '../redux/festivalsSlice';

/** Hooks */
import { useNavigateWithLoader } from '../hooks/useNavigateWithLoader';

/** Components */
import Icon from './Icon';

/**
 * <Link />
 */

const Link = forwardRef(
  ({ layout, color, className, url, target, title, loaderColor, children, ...props }, linkRef) => {
    const dispatch = useDispatch();
    const { t: __ } = useTranslation();
    const { navigate } = useNavigateWithLoader(loaderColor);

    const setIsMenuOpen = useCallback((isOpen) => dispatch(saveIsMenuOpen({ id: 'header', isOpen })), [dispatch]);

    /** Remove the host from the url for local links */
    if (!process.env.REACT_APP_PUBLIC_URL.startsWith('/') && url.startsWith(process.env.REACT_APP_PUBLIC_URL)) {
      url = url.replace(process.env.REACT_APP_PUBLIC_URL, '/');
    }

    /** Props */
    const { linkProps } = useLink({
      onPress:
        props.onPress ||
        (() => {
          if (target === '_blank') {
            window.open(url, '_blank');
          } else if (url.indexOf('tel:') > -1 || url.indexOf('mailto:') > -1) {
            window.open(url, '_self');
          } else if (url.startsWith('#')) {
            if (url === '#festivals') {
              setIsMenuOpen(true);
            } else {
              window.location.hash = url;
            }
          } else {
            navigate(url);
          }
        }),
    });
    const targetProps = target === '_blank' ? { rel: 'noreferrer noopener', target } : {};
    const langProps = { lang: props.lang, hrefLang: props.hrefLang };
    const ariaProps = { 'aria-label': props.ariaLabel, role: props.role };
    const mouseProps = { onMouseEnter: props.onMouseEnter, onMouseLeave: props.onMouseLeave };

    /** Class names */
    const classNames = ['link'];
    ['button-outline', 'button-plain'].includes(layout) && classNames.push('button');
    layout && classNames.push(`l-${layout.replace('button-', '')}`);
    color && classNames.push(`c-${color}`);
    className && classNames.push(className);

    return (
      <a
        ref={linkRef}
        {...mergeProps(linkProps, targetProps, langProps, ariaProps, mouseProps)}
        href={url}
        className={classNames.join(' ')}
        onClick={(e) => e.preventDefault()}
      >
        {children || title ? (
          children ?? title
        ) : (
          <>
            {__('button.Plus')} <Icon icon="plus" />
          </>
        )}
      </a>
    );
  }
);

Link.displayName = 'Link';

Link.propTypes = {
  ...linkPropTypes,
  /** The layout of the element */
  layout: PropTypes.oneOf(['button-outline', 'button-plain']),
  /** The color of the element */
  color: colorPropTypes,
  /** The class names to add to the element */
  className: PropTypes.string,
  /** The language of the link */
  lang: PropTypes.string,
  /** The language of the link destination */
  hrefLang: PropTypes.string,
  /** The ARIA label to set to the element */
  ariaLabel: PropTypes.string,
  /** The children of the element */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /** The color to apply to the loader after the user clicks the link */
  loaderColor: colorPropTypesNoWhite,
  /** A callback for the onPress event */
  onPress: PropTypes.func,
  /** A callback for the onMouseEnter event */
  onMouseEnter: PropTypes.func,
  /** A callback for the onMouseLeave event */
  onMouseLeave: PropTypes.func,
};

export default memo(Link);

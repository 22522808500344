import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * <Headline />
 */

const Headline = ({ i18nTitle, htmlTitle, indent, layout, className, tag: Tag }) => {
  const { t: __ } = useTranslation();

  const title = i18nTitle ? __(`headline_html.${i18nTitle}`) : htmlTitle;

  /** Class names */
  const classNames = ['headline'];
  layout && classNames.push(`l-${layout}`);
  className && classNames.push(className);

  return <Tag className={classNames.join(' ')} style={indent} dangerouslySetInnerHTML={{ __html: title }} />;
};

Headline.propTypes = {
  /** The title of the element, from the i18n translations files, must be a child of `headline_html` */
  i18nTitle: PropTypes.string,
  /** The title of the element, as a html DOMString */
  htmlTitle: PropTypes.string,
  /** The indent to apply to each line of the title as a css var. Ex: {--indent-l1: 1.25, --indent-l2: 0.75} */
  indent: PropTypes.objectOf(PropTypes.string),
  /** The layout of the element */
  layout: PropTypes.oneOf(['inline']),
  /** The class names to add to the element */
  className: PropTypes.string,
  /** The html tag to use for the element */
  tag: PropTypes.string,
};
Headline.defaultProps = {
  className: '',
  indent: {},
  tag: 'h2',
};

export default Headline;

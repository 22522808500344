import { useEffect } from 'react';

/**
 * useOnScroll Hook
 *
 * Call a given callback if the window is scrolled.
 * A delay is added to the scroll handler for better performance.
 *
 * @param {Function} callback  The callback function to call on scroll
 */
const useOnScroll = (callback) => {
  let isDelayed = false;

  /**
   * Scroll handler
   */
  const scrollHandler = () => {
    if (!isDelayed) {
      isDelayed = true;
      setTimeout(() => {
        isDelayed = false;
        callback();
      }, 100);
    }
  };

  /** Bind/unbind the scroll handler */
  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  });
};

export { useOnScroll };
export default useOnScroll;

import PropTypes from 'prop-types';
import { useRef, useImperativeHandle, useCallback, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useOverlay, FocusScope, DismissButton } from 'react-aria';

/** PropTypes */
import { colorPropTypesNoWhite } from '../propTypes';

/** Hooks */
import useCloseWithTransition from '../hooks/useCloseWithTransition';
import useOnScroll from '../hooks/useOnScroll';

/** Components */
import Button from './Button';

/**
 * <Menu />
 */

const Menu = forwardRef(({ position, layout, color, className, children, shouldCloseOnBlur, ...props }, menuRef) => {
  const { t: __ } = useTranslation();

  const overlayRef = useRef();
  const closeWithTransition = useCloseWithTransition(overlayRef, props.onClose, 250);
  useOnScroll(shouldCloseOnBlur ? closeWithTransition : () => {});

  /** Close handler */
  const onClose = useCallback(() => {
    props.onBeforeClose();
    closeWithTransition();
  }, [closeWithTransition, props]);

  /** Props */
  const { overlayProps } = useOverlay(
    {
      ...props,
      onClose,
      shouldCloseOnBlur,
      isOpen: true,
      isDismissable: true,
    },
    overlayRef
  );
  const ariaProps = {
    ...(props.id && { id: props.id }),
    ...(props['aria-labelledby'] && { 'aria-labelledby': props['aria-labelledby'] }),
  };

  /** Expose a close method to the referrer */
  useImperativeHandle(menuRef, () => ({ close: () => closeWithTransition() }), [closeWithTransition]);

  /** Class names */
  const classNames = ['menu'];
  color && (layout === 'outline' ? classNames.push(`c-${color}`) : classNames.push(`bg-${color}`));
  layout && classNames.push(`l-${layout}`);
  position && classNames.push(`p-${position}`);
  className && classNames.push(className);

  return (
    <FocusScope restoreFocus>
      <div ref={overlayRef} {...overlayProps} {...ariaProps} className={classNames.join(' ')}>
        <Button className="close-button" icon="xmark" onPress={onClose} ariaLabel={__('button.Close')} />
        {children}
        <DismissButton onDismiss={props.onClose} />
      </div>
    </FocusScope>
  );
});

Menu.displayName = 'Menu';

Menu.propTypes = {
  /** The id of the menu */
  id: PropTypes.string,
  /** The aria-labelledby prop of the menu */
  'aria-labelledby': PropTypes.string,
  /** The position of the element */
  position: PropTypes.oneOf(['left', 'right', 'top', 'bottom', 'top-left', 'bottom-right']),
  /** The layout of the element */
  layout: PropTypes.oneOf(['dropdown']),
  /** The color of the element */
  color: colorPropTypesNoWhite,
  /** The class names to add to the element */
  className: PropTypes.string,
  /** The children of the element */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /** Whether to close the menu on blur */
  shouldCloseOnBlur: PropTypes.bool,
  /** A callback for the onClose event */
  onClose: PropTypes.func.isRequired,
  /** A callback for the onBeforeClose event, before the `useCloseWithTransition` hook applies */
  onBeforeClose: PropTypes.func.isRequired,
};
Menu.defaultProps = {
  shouldCloseOnBlur: false,
  position: 'left',
  layout: 'dropdown',
  className: '',
  onBeforeClose: () => {},
};

export default Menu;

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * useMeta Hook
 *
 * Update the page meta data :
 * - The html tag lang attribute
 * - The title tag
 * - The meta tags with data-meta attributes equal to title|description|image|lang|url
 *
 * @returns {Function}  The hook callback function
 */
const useMeta = () => {
  const { t: __ } = useTranslation();

  /**
   * setMeta callback function
   *
   * @param {object} metaData  The meta data
   * @param {string} metaData.title  The title
   * @param {string} metaData.description  The description
   * @param {string} metaData.image  The image
   * @param {string} metaData.lang  The language
   * @param {string} metaData.url  The page url
   */
  const setMeta = useCallback(
    ({ description, image, lang, ...metaData }) => {
      const title = `${metaData.title} - ${__('app.siteName')}`;
      const url = metaData.url.startsWith('http')
        ? metaData.url
        : `${window.location.protocol}//${window.location.host}${metaData.url}`;
      const headElt = document.querySelector('head');

      /** Title */
      const titleElt = headElt.querySelector('title');
      titleElt.innerHTML = title;
      for (const metaTitleElt of headElt.querySelectorAll('meta[data-meta="title"]')) {
        metaTitleElt.setAttribute('content', title);
      }

      /** Description */
      for (const metaDescriptionElt of headElt.querySelectorAll('meta[data-meta="description"]')) {
        metaDescriptionElt.setAttribute('content', description);
      }

      /** Image */
      for (const metaImageElt of headElt.querySelectorAll('meta[data-meta="image"]')) {
        metaImageElt.setAttribute('content', image || `${process.env.REACT_APP_APP_URL}social.png`);
      }

      /** Lang */
      if (lang !== undefined) {
        const ucLang = lang !== 'en' ? lang.toUpperCase() : 'GB';
        const htmlElt = document.querySelector('html');
        htmlElt.setAttribute('lang', `${lang}-${ucLang}`);
        for (const metaLocaleElt of headElt.querySelectorAll('meta[data-meta="lang"]')) {
          metaLocaleElt.setAttribute('content', `${lang}_${ucLang}`);
        }
      }

      /** Url */
      for (const metaUrlElt of headElt.querySelectorAll('meta[data-meta="url"]')) {
        metaUrlElt.setAttribute('content', url);
      }
    },
    [__]
  );

  /** Return the hook callback function */
  return setMeta;
};

export { useMeta };
export default useMeta;

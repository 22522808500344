import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** Redux */
import { isLogged } from '../redux/userSlice';

/** Utils */
import { hasProperty } from '../util/Object';

/**
 * useFormValidation Hook
 *
 * Validate a form.
 *
 * @param {string[]} fields  The form fields
 * @param {string} action  The form action
 * @returns {Function}  The hook callback function
 */
const useFormValidation = (fields, action) => {
  const { t: __ } = useTranslation();

  const isUserLogged = useSelector(isLogged);

  /**
   * validateForm callback function
   *
   * @param {HTMLElement} form  The form to validate
   * @returns {Array} result
   * @returns {boolean} result.0  Whether the form is valid
   * @returns {object} result.1  An object containing the form errors
   */
  const validateForm = useCallback(
    (form) => {
      form = form && form.current ? form.current : form;
      const formData = new FormData(form);
      const errors = {};
      /** The user is logging out */
      if (action === 'login' && isUserLogged) {
        return [true, errors];
      }

      fields
        .filter((field) => field.isRequired && field.type !== 'object' && field.type !== 'array')
        .forEach(({ name }) => {
          if (!formData.has(name) || formData.get(name).length === 0) {
            errors[name] = __('form.This field is required');
          }
        });
      fields
        .filter((field) => field.type === 'email' && !hasProperty(errors, field.name))
        .forEach(({ name }) => {
          if (!formData.get(name).match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            errors[name] = __('form.The e-mail address is invalid');
          }
        });
      const isValid = Object.values(errors).filter((value) => value.length > 0).length === 0;
      return [isValid, errors];
    },
    [action, isUserLogged, fields, __]
  );

  /** Return the hook callback function */
  return validateForm;
};

export { useFormValidation };
export default useFormValidation;

import PropTypes from 'prop-types';

/**
 * <Icon />
 */

const Icon = ({ icon, layout, ariaHidden, ariaLabel }) => {
  /** Props */
  const ariaProps = {
    'aria-hidden': (ariaHidden && !ariaLabel) || null,
    'aria-label': ariaLabel,
  };

  /** Class names */
  const classNames = [`icon icon-${icon}`];
  layout && classNames.push(`l-${layout}`);

  return <span {...ariaProps} className={classNames.join(' ')}></span>;
};

Icon.propTypes = {
  /** The name of the icon */
  icon: PropTypes.oneOf([
    'facebook',
    'f',
    'instagram',
    'i',
    'twitter',
    't',
    'youtube',
    'y',
    'bars',
    'xmark',
    'xmark-bold',
    'check',
    'plus',
    'logo',
    'logo-outline',
    'blank',
    'chevron-down-light',
    'chevron-down',
    'chevron-down-bold',
    'chevron-right-light',
    'chevron-right',
    'chevron-right-bold',
    'play',
    'filters',
    'magnifier',
    'map',
    'clock',
    'map-marker',
    'promoter',
    'arrow-left',
    'arrow-right',
    'calendar',
    'email',
    'website',
    'tel',
    'user',
    'wallet',
    'exclamation',
    'language',
    'heart',
    'tickets',
  ]).isRequired,
  /** The layout of the element */
  layout: PropTypes.oneOf(['round', 'square-outline']),
  /** Whether to hide the element for ARIA */
  ariaHidden: PropTypes.bool,
  /** The ARIA label to set to the element */
  ariaLabel: PropTypes.string,
};
Icon.defaultProps = {
  ariaHidden: true,
};

export default Icon;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

export const languageNames = {
  fr: 'Français',
  // nl: 'Nederlands', // TODO: remove this to restore language
  // en: 'English', // TODO: remove this to restore language
};

i18n
  /**
   * Load translation using http
   *
   * @see /public/locales
   * @see https://github.com/i18next/i18next-http-backend
   */
  .use(Backend)
  /**
   * Detect user language
   *
   * @see https://github.com/i18next/i18next-browser-languageDetector
   */
  // .use(LanguageDetector) // TODO: remove this to restore language detector
  /**
   * Pass the i18n instance to react-i18next
   */
  .use(initReactI18next)
  /**
   * Initialize i18next
   *
   * For all options @see https://www.i18next.com/overview/configuration-options
   */
  .init({
    /**
     * Detection fallback order
     *
     * @see https://www.i18next.com/principles/fallback#fallback-to-different-languages
     */
    fallbackLng: Object.keys(languageNames),

    /**
     * Language resource files for dev & prod env's
     *
     * @see https://www.i18next.com/how-to/add-or-load-translations#load-using-a-backend-plugin
     */
    backend: {
      loadPath:
        process.env.NODE_ENV === 'development'
          ? '/locales/{{lng}}/{{ns}}.json'
          : `${process.env.REACT_APP_APP_URL}locales/{{lng}}/{{ns}}.json`,
    },

    /**
     * Custom detection params to remove the browser language from language detection
     *
     * @see https://github.com/i18next/i18next-browser-languageDetector#detector-options
     */
    detection: {
      order: ['cookie', 'localStorage', 'sessionStorage'],
    },

    /**
     * Log infos to console
     *
     * @see https://www.i18next.com/overview/configuration-options#logging
     */
    debug: process.env.NODE_ENV === 'development',

    /**
     * Interpolation options
     *
     * @see https://www.i18next.com/translation-function/interpolation#all-interpolation-options
     */
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** Redux */
import { savePagesList as saveUserPages, saveError as saveUserError } from '../redux/userSlice';

/** Utils */
import { fetchAndParseJSON } from '../util/Fetch';

/**
 * useFetchUserPages Hook
 *
 * Fetch the user private pages list from the app and save it to Redux.
 * If an error occured, the error message is saved to Redux.
 *
 * @returns {Function}  The hook callback function
 */
const useFetchUserPages = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  /**
   * fetchPages memoized callback function
   *
   * @param {object} user  The user
   * @returns {Promise}  A promise to the pages
   */
  const fetchPages = useCallback(
    (user) =>
      new Promise((resolve, reject) => {
        fetchAndParseJSON(`${process.env.REACT_APP_REST_URL}user`, {
          'X-APP-USER': encodeURIComponent(window.btoa(`${user.email}:${user.token}`)),
          'X-APP-LANGUAGE': i18n.language,
        })
          .then((pages) => {
            dispatch(saveUserPages(pages));
            return resolve(pages);
          })
          .catch((error) => {
            dispatch(saveUserError(error.message));
            return reject(error);
          });
      }),
    [dispatch, i18n.language]
  );

  /** Return the hook callback function */
  return fetchPages;
};

export { useFetchUserPages };
export default useFetchUserPages;

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * useLanguageDetector Hook
 *
 * Verify autodetected language and update it if necessary.
 */
const useLanguageDetector = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const i18nLanguage = i18n.language.substring(0, 2);
    if (!i18n.languages.includes(i18nLanguage)) {
      /** Set the language to the default language if the detected language is not supported */
      i18n.changeLanguage(process.env.REACT_APP_DEFAULT_LANGUAGE);
    } else if (i18n.language.length > 2) {
      /** Format the detected language to a two-letter language code */
      i18n.changeLanguage(i18nLanguage);
    }
    /** Redirect the page to the language folder */
    if (i18n.language !== process.env.REACT_APP_DEFAULT_LANGUAGE && window.location.pathname === '/') {
      window.location.replace(`${window.location.href + i18n.language}/`);
    }
  }, [i18n]);
};

export { useLanguageDetector };
export default useLanguageDetector;

import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

/** Redux */
import { sortByDateTime, filterByFestivalId, filterValue } from './eventsSlice';

/**
 * A Redux slice to store the user data
 */

// eslint-disable-next-line jsdoc/valid-types
/** @typedef {import('./appSlice.js').Page} Page A page */

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: 0,
    name: '',
    email: '',
    token: '',
    role: '',
    /** @type {number[]} */
    festivals: [],
    pages: {
      isLoaded: false,
      /** @type {Page[]} */
      list: [],
      content: {},
    },
    events: {
      isLoaded: false,
      /** @type {Page[]} */
      list: [],
      currentPageIndex: 1,
      perPage: 12,
      filters: {
        /** @type {number[]} */
        'data.festival': [],
        /** @type {string[]} */
        'data.status': [],
        /** @type {boolean[]} */
        'data.isPast': [false],
        search: '',
      },
    },
    cookies: {
      maps: false,
      // forms: false,
    },
  },
  reducers: {
    /**
     * Save the user.
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {object} action.payload  The reducer data
     * @param {number} action.payload.id  The user id
     * @param {string} action.payload.name  The user name
     * @param {string} action.payload.email  The user e-mail
     * @param {string} action.payload.token  The application token
     * @param {string} action.payload.role  The user role
     * @param {number[]} action.payload.festivals  The user festivals id's
     */
    saveUser: (state, { payload: { id, name, email, token, role, festivals } }) => {
      state.id = id;
      state.name = name;
      state.email = email;
      state.token = token;
      state.role = role;
      state.festivals = festivals;
      state.events.filters['data.festival'] = festivals.length === 1 ? [festivals[0]] : [];
    },

    /**
     * Delete the user.
     *
     * @param {object} state  The redux state
     */
    deleteUser: (state) => {
      state.id = 0;
      state.name = '';
      state.email = '';
      state.token = '';
      state.role = '';
      state.festivals = [];
      state.pages.isLoaded = false;
      state.pages.list = [];
      state.pages.content = {};
      state.events.isLoaded = false;
      state.events.list = [];
      state.events.currentPageIndex = 1;
      state.events.perPage = 12;
      state.events.filters['data.festival'] = [];
      state.events.filters['data.status'] = [];
      state.events.filters['data.isPast'] = [false];
    },

    /**
     * Save the loading error.
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {string} action.payload  The loading error
     */
    saveError: (state, action) => {
      state.error = action.payload;
    },

    /**
     * Save the user pages.
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {string} action.payload  The user pages
     */
    savePagesList: (state, action) => {
      state.pages.isLoaded = true;
      state.pages.list = action.payload;
    },

    /**
     * Delete the user pages.
     *
     * @param {object} state  The redux state
     */
    deletePagesList: (state) => {
      state.pages.list = [];
    },

    /**
     * Save the `pages.isLoading` state
     *
     * @param {object} state  The redux state
     */
    saveIsPagesListLoaded: (state) => {
      state.pages.isLoaded = false;
    },

    /**
     * Save the content of a page.
     *
     * @param {object} state  The redux state
     * @param {object} state.pages  The user pages
     * @param {object} action  The reducer action
     * @param {string} action.payload  The user pages
     * @param {Page.pageName} action.payload.pageName  The page name
     * @param {string} action.payload.content  The content
     */
    savePageContent: ({ pages }, { payload: { pageName, content } }) => {
      pages.content[pageName] = content;
    },

    /**
     * Save a value to the content of a page.
     *
     * @param {object} state  The redux state
     * @param {object} state.pages  The user pages
     * @param {object} state.pages.content  The user content
     * @param {object} action  The reducer action
     * @param {string} action.payload  The user pages
     * @param {Page.pageName} action.payload.pageName  The page name
     * @param {string} action.payload.fieldName  The field name
     * @param {any} action.payload.value  The value to save
     */
    savePageData: ({ pages: { content } }, { payload: { pageName, fieldName, value } }) => {
      if (content[pageName] === undefined) {
        content[pageName] = {};
      }
      content[pageName][fieldName] = { value, errorMessage: '' };
    },

    /**
     * Save the events list
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {Page[]} action.payload  The events list
     */
    saveEventsList: (state, action) => {
      // const list = createEventsFromDates(action.payload);
      const list = action.payload
        .filter((event) => event.data.dates.length > 0)
        .map((event) => {
          const { startDate, startTime, endDate, endTime } = event.data.dates[0];
          return {
            ...event,
            data: {
              ...event.data,
              startDate,
              startTime,
              endDate,
              endTime,
              isPast: event.data.dates.filter((date) => date.isPast === false).length === 0,
            },
          };
        });
      state.events.list = sortByDateTime(list);
      state.events.isLoaded = true;
      process.env.NODE_ENV === 'development' && console.info(state.events.list);
    },

    /**
     * Save the number of events list items to display per page
     *
     * @param {object} state  The Redux state
     * @param {object} action  The reducer action
     * @param {object} action.payload  The number of items to display per page
     */
    saveEventsPerPage: (state, action) => {
      state.events.perPage = action.payload;
      state.currentPageIndex = 1;
    },

    /**
     * Save the current events page index
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {number} action.payload  The current page index
     */
    saveCurrentEventsPageIndex: (state, action) => {
      state.events.currentPageIndex = action.payload;
    },

    /**
     * Clear all the active events filters, except those given in the `except` array, if provided.
     *
     * @param {object} state  The Redux state
     * @param {object} action  The reducer action
     * @param {object} action.payload  The reducer data
     * @param {string[]} action.payload.except  The filter that must not be cleared
     */
    clearEventsFilters: (state, action) => {
      Object.keys(state.events.filters).forEach((filterKey) => {
        if (!action.payload || !action.payload.except.includes(filterKey)) {
          state.filters[filterKey] = filterKey !== 'search' ? [] : '';
        }
      });
    },

    /**
     * Save the given events filter
     *
     * @param {object} state  The Redux state
     * @param {object} action  The reducer action
     * @param {object} action.payload  The reducer data
     * @param {string} action.payload.filterKey  The filter key
     * @param {string[]|number[]} action.payload.values  The filter values
     */
    saveEventsFilter: (state, action) => {
      state.events.filters[action.payload.filterKey] = action.payload.values;
      state.events.currentPageIndex = 1;
    },

    /**
     * Save the cookie status.
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {object} action.payload  The reducer data
     * @param {string} action.payload.name  The cookie name
     * @param {boolean} action.payload.value  The cookie value
     */
    saveCookie: (state, { payload: { name, value } }) => {
      state.cookies[name] = value;
    },
  },
});

export const {
  saveUser,
  deleteUser,
  saveError,
  savePagesList,
  deletePagesList,
  saveIsPagesListLoaded,
  savePageContent,
  savePageData,
  saveEventsList,
  saveEventsPerPage,
  saveCurrentEventsPageIndex,
  clearEventsFilters,
  saveEventsFilter,
  saveCookie,
} = userSlice.actions;

/**
 * Return whether the user is logged in.
 *
 ** This does not dispense with using the useAuth hook before exposing critical data
 *
 * @returns {boolean}  Whether the user is logged in
 */
export const isLogged = createSelector(
  (state) => state.user,
  (user) =>
    user.id !== 0 &&
    user.name !== '' &&
    user.email !== '' &&
    user.token !== '' &&
    user.role !== '' &&
    user.festivals !== []
);

/**
 * Return whether the user has publish rights
 *
 * @returns {boolean}  Whether the user has publish rights
 */
export const selectHasPublishRights = createSelector(
  (state) => state.user,
  (user) => ['administrator', 'editor', 'author'].includes(user.role)
);

/**
 * Return a page in a given list, by its name and a given language
 *
 * @param {Page[]} list  The pages list
 * @param {string} pageName  The page name
 * @param {string} language  The page language
 * @returns {Page}  The desired page
 */
export const getPage = (list, pageName, language) =>
  list.filter((page) => page.pageName === pageName && page.lang === language)[0];

/**
 * Return a page by its name and a given language
 *
 * @param {object} state  The React state
 * @param {string} pageName  The page name
 * @param {string} language  The page language
 * @returns {Page}  The desired page
 */
export const selectPage = createSelector(
  (state) => state.user.pages,
  (_, pageName) => pageName,
  (_, __, language) => language,
  (pages, pageName, language) => getPage(pages.list, pageName, language)
);

/**
 * Return the content of a page
 *
 * @param {object} state  The React state
 * @param {string} pageName  The page name
 * @returns {Page}  The desired page
 */
export const selectPageContent = createSelector(
  (state) => state.user.pages,
  (_, pageName) => pageName,
  (pages, pageName) => pages.content[pageName]
);

/**
 * Return the pages list filtered by a given language
 *
 * @param {string} language  The festivals language
 * @returns {Page[]}
 */
export const selectPagesList = createSelector(
  (state) => state.user.pages,
  (_, language) => language,
  (pages, language) => pages.list.filter((page) => page.lang === language)
);

/**
 * Return the events list filtered by a given language
 *
 * @param {number} festivalId  The id of the festival, if provided, the list will be filtered by festival
 * @returns {object}
 */
export const selectEventsList = createSelector(
  (state) => state.user.events,
  (_, festivalId) => festivalId,
  (events, festivalId) => events.list.filter((event) => filterByFestivalId(festivalId, event))
);

/**
 * Return a slice the events list filtered by a given language
 *
 * @param {number} start  The slice start index
 * @param {number} length  The slice length
 * @param {number} festivalId  The id of the festival, if provided, the list will be filtered by festival
 * @returns {object}
 */
export const selectEventsSlice = createSelector(
  (state) => state.user.events,
  (_, start) => start,
  (_, __, length) => length,
  (_, __, ___, festivalId) => festivalId,
  (events, start, length, festivalId) =>
    events.list
      .filter((event) => filterByFestivalId(festivalId, event))
      .slice(
        start || (events.currentPageIndex - 1) * events.perPage,
        (start || (events.currentPageIndex - 1) * events.perPage) + (length || events.perPage)
      )
);

/**
 * Return the events list, after the Redux filters have been applied.
 *
 * @returns {object}  The events list
 */
export const selectFilteredEventsList = createSelector(
  (state) => state.user.events,
  (events) => events.list.filter((event) => filterValue(events.filters, event))
);

/**
 * Return a slice the events list, after the Redux filters have been applied.
 *
 * @param {number} start  The start index
 * @param {number} length  The slice length
 * @returns {object}  The events list slice
 */
export const selectFilteredEventsSlice = createSelector(
  (state) => state.user.events,
  (_, start) => start,
  (_, __, length) => length,
  (events, start, length) =>
    events.list
      .filter((event) => filterValue(events.filters, event))
      .slice(
        start || (events.currentPageIndex - 1) * events.perPage,
        (start || (events.currentPageIndex - 1) * events.perPage) + (length || events.perPage)
      )
);

export default userSlice.reducer;

/**
 * Returns a path to logo file for a desired language in an object of paths keyed by language
 *
 * @param {object} logos  An object containing the logos, keyed by a 2-letter language code
 * @param {string} language  A 2-letter language code for the desired language
 * @returns {string}  A path to the logo
 */
export const getLogoByLanguage = (logos, language) => {
  // const logo = { fr: logoFR, nl: logoNL };
  return Object.prototype.hasOwnProperty.call(logos, language) ? logos[language] : Object.values(logos)[0];
};

import { useEffect } from 'react';

/**
 * @event window#custom-resize
 * @type {object}
 * @property {boolean} width  Indicates if the width has been changed
 * @property {boolean} height  Indicates if the height has been changed
 */

/**
 * useCustomResize Hook
 *
 * Call a given callback if the window is resized.
 * A delay is added to the resize handler for better performance.
 *
 * @fires window#custom-resize
 */
const useCustomResize = () => {
  let isDelayed = false;
  let windowWidth = window.innerWidth;
  let windowHeight = window.innerHeight;

  /**
   * Resize handler
   */
  const resizeHandler = () => {
    if (!isDelayed) {
      isDelayed = true;
      setTimeout(() => {
        isDelayed = false;
        if (windowWidth !== window.innerWidth || windowHeight !== window.innerHeight) {
          /**
           * Fires a `custom-resize` event.
           *
           * @fires window#custom-resize
           */
          window.dispatchEvent(
            new CustomEvent('custom-resize', {
              detail: {
                width: windowWidth !== window.innerWidth,
                height: windowHeight !== window.innerHeight,
              },
            })
          );

          /** Store new sizes */
          windowWidth = window.innerWidth;
          windowHeight = window.innerHeight;
        }
      }, 100);
    }
  };

  /** Bind/unbind the resize handler */
  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    window.customResize = true;
    return () => {
      window.removeEventListener('resize', resizeHandler);
      window.customResize = false;
    };
  });
};

export { useCustomResize };
export default useCustomResize;

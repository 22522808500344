/**
 * Reduce an array of objects to an unique object
 *
 * @param {Array} array  The array to reduce
 * @returns {object}  The reduced object
 * @example [{fr:'fr'}, {nl:'nl'}, {en:'en'}] => {fr:'fr', nl:'nl', en:'en'}
 */
export const reduceToObject = (array) =>
  array.reduce((previousValue, currentValue) => {
    previousValue[Object.keys(currentValue)[0]] = Object.values(currentValue)[0];
    return previousValue;
  }, {});

import { PropTypes } from 'prop-types';
import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** Redux */
import {
  isLogged,
  saveUser,
  deleteUser,
  savePagesList as saveUserPages,
  deletePagesList as deleteUserPages,
} from '../../redux/userSlice';

/** Utils */
import { saveCookie, deleteCookie } from '../../util/Cookies';

/** Components */
import Form from './Form';

/**
 * <LoginForm />
 */

const LoginForm = (props) => {
  const dispatch = useDispatch();
  const { t: __ } = useTranslation();

  const isUserLogged = useSelector(isLogged);

  const fields = useMemo(
    () =>
      (!isUserLogged
        ? [
            {
              name: 'login',
              type: 'text',
              label: __('form.Login or e-mail'),
              isRequired: true,
            },
            {
              name: 'password',
              type: 'password',
              label: __('form.Password'),
              isRequired: true,
            },
          ]
        : []), // prettier-ignore
    [__, isUserLogged]
  );

  return (
    <Form
      type="login"
      action="login"
      fields={fields}
      label={!isUserLogged ? __('form.Login form') : __('form.Logout form')}
      buttonLabel={!isUserLogged ? __('button.Login') : __('button.Logout')}
      onLogin={(data) => {
        const { message, pages, ...user } = data;
        saveCookie(process.env.REACT_APP_USER_COOKIE, JSON.stringify(user), 30);
        dispatch(saveUserPages(pages));
        dispatch(saveUser(user));
        props.onLogin({ user, pages });
      }}
      onLogout={(data) => {
        deleteCookie(process.env.REACT_APP_USER_COOKIE);
        dispatch(deleteUserPages());
        dispatch(deleteUser());
        props.onLogout();
      }}
    />
  );
};

LoginForm.propTypes = {
  /** A callback for the OnLogin event */
  onLogin: PropTypes.func,
  /** A callback for the OnLogout event */
  onLogout: PropTypes.func,
};
LoginForm.defaultProps = {
  onLogin: () => {},
  onLogout: () => {},
};

export default LoginForm;
